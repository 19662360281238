import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { sortAlphabetically } from '../../helpers/sort-by-letter'
import {
  FilterButtons, Spinner, SelectComponent, Seo,
} from '../../components'
import SearchIcon from '../../assets/icons/pacificcross_icons_search.svg'
import './key-terms-styles.scss'

const KeyTermsView = ({
  data,
  letters,
  loading,
  categories,
  onHandleFilterByLetter,
  onHandleFilterByCategory,
  debouncedChangeHandler,  
}) => {
  const { t, i18n } = useTranslation()
  const breakpoints = useBreakpoint()
  const arrayCategory = _.reduce(
    categories,
    (acc, tab, idx) => {
      acc.push({
        id: idx + 1,
        name: tab.name,
        slug: tab.slug,
        label: tab.name,
        value: tab.slug,
        activeClass: idx === 0 && 'filter-active',
        translateValue: idx === 0 ? '0' : `${110 * idx}px`,
      })
      return acc
    },
    [],
  )
  // console.log("gas ", i18n.language)
  // console.log("gas ", data)
  const result = sortAlphabetically(data)
  const uppercaseLetters = _.map(letters, (letter) => _.upperCase(letter))
  const alpha = Array.from(Array(26)).map((e, i) => i + 65)
  const alphabet = alpha.map((x) => ({
    letter: String.fromCharCode(x),
    active: _.includes(uppercaseLetters, String.fromCharCode(x)),
  }))

  return (
    <div className="container-fluid p-0">
      <Seo title={`${t('seo.title')} Key Terms`} />
      <div className="container-xxl">
        <div className="keyTerms__container__title">
          <h1 className="title-page">{t('keyTerms.title')}</h1>
          <div className="keyTerms__search__box">
            <SearchIcon width="20" fill="#DEDEDE" color="#DEDEDE" />
            <input onChange={debouncedChangeHandler} placeholder="Search" />
          </div>
        </div>
        <div className="keyTerms__container__filters">
          {breakpoints.xs ? (
            <SelectComponent
              blue={true}
              placeholder={t('keyTerms.filter.placeholder', {
                number: arrayCategory.length - 1,
              })}
              options={arrayCategory}
              onChange={(e) => onHandleFilterByCategory(e.value)}
            />
          ) : (
            arrayCategory.length >= 2 && (
              <FilterButtons
                width={110}
                data={arrayCategory}
                onHandleFilter={onHandleFilterByCategory}
              />
            )
          )}
        </div>
      </div>
      <div className="keyTerms__alphabet">
        <div className="container-xxl keyTerms__row__letters">
          {_.map(alphabet, (alph) => (
            <p
              onClick={
                alph.active
                  ? () => onHandleFilterByLetter(alph.letter)
                  : undefined
              }
              key={alph.letter}
              className={alph.active && 'active'}
            >
              {alph.letter}
            </p>
          ))}
        </div>
      </div>
      {loading ? (
        <Spinner loading={loading} />
      ) : (
        <div className="container-xxl">
          {_.map(result, (item, idx) => (
            <div className="row" key={idx}>
              <div className="col-12 col-sm-3 keyTerms__column__key">
                <p>{item.group}</p>
              </div>
              <div className="col-12 col-sm-9">
                {_.map(item.children, (child, i) => (
                  <div key={i} className="keyTerms__column__content">
                    <h3>{child.name}</h3>
                    <p>{child.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default KeyTermsView
